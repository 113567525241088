/* Add your custom styles here */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem; /* Adjust padding for smaller screens */
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
    margin: 0; /* Remove default margins */
}

.login-form {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    margin: 0; /* Remove default margins */
}

.form-group {
    margin-bottom: 1rem; /* Reduced margin-bottom */
}

.form-label {
    font-weight: bold;
    margin-bottom: 0.5rem; /* Reduced margin-bottom */
}

.form-control {
    border-radius: 5px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
    border-color: #0056b3;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

.form-text {
    font-size: 0.875rem;
    margin-top: 0.25rem; /* Reduced margin-top */
}

.form-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.link {
    color: #0056b3;
    text-decoration: none;
    transition: color 0.3s ease;
}

.link:hover {
    color: #003d7a;
}

.btn-dark {
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:hover {
    background-color: #23272b;
    border-color: #1d2124;
}
