.dashboard-container {
    padding: 20px;
}

.scan-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.scan-card:hover {
    transform: translateY(-5px);
}

.scan-button {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.scan-button:hover {
    background-color: #0056b3;
}

.form-control {
    border-radius: 5px;
    border: 1px solid #ced4da;
    padding: 10px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.custom-link {
    display: inline-block;
    margin-top: 10px;
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

.custom-link:hover {
    color: #0056b3;
    text-decoration: underline;
}

.task-table {
    border-radius: 5px;
    overflow: hidden;
}

.task-table thead {
    background-color: #0d0d0d;
    color: #ffffff;
}

.task-table tbody tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

.badge-success {
    background-color: #28a745;
}

.badge-danger {
    background-color: #dc3545;
}

.badge-warning {
    background-color: #ffc107;
}

.alert-info {
    background-color: #d1ecf1;
    color: #0c5460;
    border-radius: 5px;
    border: 1px solid #bee5eb;
}

.breadcrumb {
    background-color: transparent;
    margin-bottom: 20px;
}

.breadcrumb-item a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.breadcrumb-item a:hover {
    color: #0056b3;
}

.custom-links-container {
    margin-top: 15px;
    display: flex;
    gap: 15px;
}

.custom-button-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #0c0d0f;
    color: #ffffff;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.2s ease-in-out;
}

.custom-button-link:hover {
    background-color: #040608;
    transform: translateY(-2px);
}

.custom-button-link:active {
    transform: translateY(1px);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .dashboard-container {
        padding: 10px;
    }

    .row {
        flex-direction: column;
    }

    .col-4,
    .col-8 {
        width: 100%;
        margin-bottom: 20px;
    }

    .scan-card {
        margin-bottom: 20px;
    }

    .custom-links-container {
        flex-direction: column;
    }

    .custom-button-link {
        width: 100%;
        text-align: center;
    }

    .scan-button {
        width: 100%;
        padding: 12px;
        font-size: 18px;
    }
}

@media (max-width: 576px) {
    .scan-button {
        font-size: 16px;
    }

    .form-control {
        padding: 8px;
    }

    .scan-card {
        border-radius: 8px;
    }
}
