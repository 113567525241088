/* Header.css */

/* General styling for the navigation bar */
.header-nav {
    background-color: #343a40;
    border-bottom: 1px solid #dee2e6;
    padding: 0.5rem 1rem;
}

.header-nav .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Allow wrapping for mobile */
}

.brand-logo {
    width: 200px; /* Set a larger logo size */
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 3px solid #f2f2f0; /* Adding border to make the logo stand out */
    border-radius: 8px; /* Rounded corners for a modern look */
    padding: 5px; /* Padding around the logo */
    background-color: #ffffff; /* Background color for better visibility */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

.brand-logo:hover {
    transform: scale(1.1); /* Slightly enlarges the logo on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Increased shadow on hover */
}

.nav-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.nav-item {
    margin-left: 1rem; /* Adjusted margin for better spacing */
}

.nav-link {
    color: #f8f9fa;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease, padding 0.3s ease;
    padding: 0.5rem;
    border-radius: 4px;
    font-size: 16px; /* Increase font size for better readability */
}

.nav-link:hover {
    color: #f2f2ef;
    background-color: #495057;
}

/* Styling for the logout link */
.logout-link {
    cursor: pointer;
}

/* Styling for the main header */
.header-main {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    padding: 1rem 0;
}

.header-main .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-form {
    display: flex;
    flex-grow: 1;
    max-width: 500px; /* Maximum width for the search bar */
}

.search-input {
    flex-grow: 1;
    padding: 0.75rem 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-input:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

/* Mobile view adjustments */
@media (max-width: 768px) {
    .header-nav .container {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items on mobile */
        text-align: center; /* Center text for better alignment */
    }

    .brand-logo {
        width: 150px; /* Adjust logo size for mobile */
    }

    .nav-list {
        flex-direction: column; /* Stack nav items vertically */
        width: 100%; /* Take full width */
    }

    .nav-item {
        margin: 0.5rem 0; /* Add spacing between items */
    }

    .nav-link {
        font-size: 18px; /* Increase font size for better readability on mobile */
    }

    .header-main {
        padding: 0.5rem 0; /* Adjust padding for mobile */
    }

    .search-input {
        padding: 0.5rem; /* Adjust input padding for mobile */
        font-size: 16px; /* Increase input font size for readability */
    }
}
