.page-title {
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    font-size: 1.5rem; /* Increased font size for better visibility */
}

.group-item {
    transition: background-color 0.3s, box-shadow 0.3s;
}

.group-item:hover {
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.select-all-btn, .add-btn, .remove-btn, .proceed-btn {
    transition: background-color 0.3s, color 0.3s;
}

.select-all-btn:hover {
    background-color: #030304;
    color: #fff;
}

.add-btn:hover {
    background-color: #0e0e0f;
    color: #fff;
}

.cart-title {
    font-weight: bold;
    color: #333;
}

.cart-list {
    background: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.remove-btn:hover {
    background-color: #dc3545;
}

.proceed-btn:hover {
    background-color: #218838;
    color: #fff;
}

/* Responsive styles */
@media (max-width: 768px) {
    .page-title {
        font-size: 1.2rem; /* Adjusted font size for mobile */
    }

    .list-group-item {
        font-size: 0.9rem; /* Smaller font for mobile */
    }

    .btn {
        width: 100%; /* Full-width buttons on mobile */
        margin: 5px 0; /* Spacing between buttons */
    }

    .cart-list {
        height: 200px; /* Reduced height for mobile */
    }
}
