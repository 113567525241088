.verified {
    background-color: greenyellow;
    color: black;
    padding: 2px 4px;
    border-radius: 2px;
}

.not-verified {
    background-color: orange;
    color: black;
    padding: 2px 4px;
    border-radius: 2px;
}


.is_active {
    background-color: greenyellow;
    color: black;
    padding: 2px 4px;
    border-radius: 2px;
}

.not-active {
    background-color: orange;
    color: black;
    padding: 2px 4px;
    border-radius: 2px;
}